.login-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  .login-title {
    text-align: center;
    margin-top: 2em;
    font-weight: 900;
    color: grey;
    h1 {
      font-size: 10em;
    }
    p {
      text-align: center;
    }
    .login-logo {
      width: 380px;
      margin-bottom: 2em;
    }
  }
}
