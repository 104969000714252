// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
@import "./Colours.scss";
@import "~bootstrap/scss/bootstrap";

// Then add additional custom code here

.form-label {
  padding: 0px 5px;
  margin: 0px;
  color: $primary;
  font-size: 1rem;
  font-weight: 700;
}
